import {Grid, Typography} from '@mui/material';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {AsyncBiometricsLoader} from 'components/AsyncBiometricsLoader/AsyncBiometricsLoader';
import {AsyncPastEventsLoader} from 'components/AsyncPastEventsLoader/AsyncPastEventsLoader';
import {AsyncRawHealthDataLoader} from 'components/AsyncRawHealthDataLoader/AsyncRawHealthDataLoader';
import {AsyncVaccinationsLoader} from 'components/AsyncVaccinationsLoader/AsyncVaccinationsLoader';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

type HealthDashboardPageProps = {
  header?: boolean;
};

export default function HealthDashboardPage({
  header,
}: HealthDashboardPageProps) {
  const {t} = useTranslation();

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('dashboard-header')}`;
  }, []);

  const VaccinationButton = <AsyncVaccinationsLoader />;
  const RawHealthDataButton = <AsyncRawHealthDataLoader />;

  return (
    <Layout header={header ? <DashboardHeader /> : undefined}>
      <section>
        <AsyncBiometricsLoader />
      </section>
      <section>
        <AsyncPastEventsLoader />
      </section>
      {(VaccinationButton || RawHealthDataButton) && (
        <section>
          <div style={{marginTop: '20px'}}>
            <Typography component={'h2'} variant="display6">
              More
            </Typography>
            <Grid container spacing={2} style={{marginTop: '12px'}}>
              {VaccinationButton && (
                <Grid item xs={6} md={3}>
                  {VaccinationButton}
                </Grid>
              )}
              {RawHealthDataButton && (
                <Grid item xs={6} md={3}>
                  {RawHealthDataButton}
                </Grid>
              )}
            </Grid>
          </div>
        </section>
      )}
    </Layout>
  );
}

function DashboardHeader() {
  const {t} = useTranslation();

  return <Header headerText={t('dashboard-header')} hasBackButton={false} />;
}
