import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

export function AsyncVaccinationsLoader() {
  const [vaccinations, setVaccinations] = useState<JSX.Element | null>(null);

  const vaccinationsEnabled = config.getBoolean(
    'FEATURE_PHR_VACCINATIONS_ENABLED'
  );

  useEffect(() => {
    async function loadVaccinations() {
      const module = await System.import('@verily-src/phr-vaccinations-mfe');
      setVaccinations(<module.VaccinationsPrimaryCard />);
    }

    if (vaccinationsEnabled) {
      loadVaccinations();
    }
  }, []);

  return vaccinations;
}
