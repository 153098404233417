import {CssBaseline, ThemeProvider} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {AsyncRawDataDetailsLoader} from 'components/AsyncRawDataDetailsLoader/AsyncRawDataDetailsLoader';
import {AsyncRawDataPageLoader} from 'components/AsyncRawDataPageLoader/AsyncRawDataPageLoader';
import BiometricsDetailsPage from 'pages/BiometricsDetailsPage/BiometricsDetailsPage';
import HealthDashboardPage from 'pages/HealthDashboardPage/HealthDashboardPage';
import PastEventsPage from 'pages/PastEventsPage/PastEventsPage';
import VaccinationsPage from 'pages/VaccinationsPage/VaccinationsPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

export default function Root() {
  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route
            path="/me/health-dashboard"
            element={<HealthDashboardPage header />}
          ></Route>
          <Route
            path="/me/mobile/health-dashboard"
            element={<HealthDashboardPage />}
          ></Route>
          <Route
            path="/me/mobile/health-dashboard/raw-data"
            element={<AsyncRawDataPageLoader />}
          />
          <Route
            path="/me/mobile/health-dashboard/raw-data/*"
            element={<AsyncRawDataDetailsLoader />}
          />
          {/* Move past events route to Past Events MFE */}
          <Route
            path="/me/mobile/health-dashboard/past-events"
            element={<PastEventsPage />}
          ></Route>
          {/* Move vaccinations route to Vaccinations MFE */}
          <Route
            path="/me/mobile/health-dashboard/vaccinations"
            element={<VaccinationsPage />}
          ></Route>
          {/* TODO(ONEVERILY-50592): Move biometrics route to Biometrics MFE */}
          <Route
            path="/me/mobile/health-dashboard/biometrics"
            element={<BiometricsDetailsPage />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
