import {useEffect, useState} from 'react';

export function AsyncRawDataDetailsLoader() {
  const [rawDataDetails, setRawDataDetails] = useState<JSX.Element | null>(
    null
  );

  useEffect(() => {
    async function loadRawDataDetails() {
      const module = await System.import('@verily-src/phr-past-events-mfe');
      setRawDataDetails(<module.RawDataSecondaryPage />);
    }

    loadRawDataDetails();
  }, []);

  return rawDataDetails;
}
