import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

export function AsyncVaccinationsPageLoader() {
  const [vaccinations, setVaccinations] = useState<JSX.Element | null>(null);

  // TODO: Delete feature flag after merged to production
  const vaccinationsEnabled = config.getBoolean(
    'FEATURE_PHR_VACCINATIONS_ENABLED'
  );

  useEffect(() => {
    async function loadVaccinations() {
      const module = await System.import('@verily-src/phr-vaccinations-mfe');
      console.log(module, module.Vaccinations);
      setVaccinations(<module.VaccinationsSecondaryPage />);
    }

    if (vaccinationsEnabled) {
      loadVaccinations();
    }
  }, []);

  if (!vaccinations) {
    return null;
  }

  return vaccinations;
}
