import {config} from '@verily-src/phaf-runtime-helpers';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import BiometricsDetailsPage from 'pages/BiometricsDetailsPage/BiometricsDetailsPage';
import HealthDashboardPage from 'pages/HealthDashboardPage/HealthDashboardPage';
import PastEventsPage from 'pages/PastEventsPage/PastEventsPage';
import VaccinationsPage from 'pages/VaccinationsPage/VaccinationsPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

export default function Root() {
  const isDarkModeEnabled = config.getBoolean('FEATURE_DARK_MODE_ENABLED');

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={isDarkModeEnabled}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/me/health-dashboard"
            element={<HealthDashboardPage header />}
          ></Route>
          <Route
            path="/me/mobile/health-dashboard"
            element={<HealthDashboardPage />}
          ></Route>
          {/* TODO: Move past events route to Past Events MFE */}
          <Route
            path="/me/mobile/health-dashboard/past-events"
            element={<PastEventsPage />}
          ></Route>
          {/* TODO: Move vaccinations route to Vaccinations MFE */}
          <Route
            path="/me/mobile/health-dashboard/vaccinations"
            element={<VaccinationsPage />}
          ></Route>
          {/* TODO(ONEVERILY-50592): Move biometrics route to Biometrics MFE */}
          <Route
            path="/me/mobile/health-dashboard/biometrics"
            element={<BiometricsDetailsPage />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </VerilyMeThemeProvider>
  );
}
