import {useEffect, useState} from 'react';

export function AsyncRawHealthDataLoader() {
  const [rawHealthData, setRawHealthData] = useState<JSX.Element | null>(null);

  useEffect(() => {
    async function loadRawHealthData() {
      const module = await System.import('@verily-src/phr-past-events-mfe');
      setRawHealthData(<module.RawHealthDataCard />);
    }

    loadRawHealthData();
  }, []);

  return rawHealthData;
}
