import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

export function AsyncBiometricsDetailsLoader() {
  const [biometrics, setBiometrics] = useState<JSX.Element | null>(null);

  // TODO(ONEVERILY-50349): Delete feature flag after merged to production
  const biometricsEnabled = config.getBoolean(
    'FEATURE_DEVICE_BIOMETRICS_ENABLED'
  );

  useEffect(() => {
    async function loadBiometrics() {
      const module = await System.import(
        '@verily-src/device-biometrics-patient-mfe'
      );
      setBiometrics(<module.DetailsView />);
    }

    if (biometricsEnabled) {
      loadBiometrics();
    }
  }, []);

  if (!biometrics) {
    return null;
  }

  return biometrics;
}
