import {useEffect, useState} from 'react';

export function AsyncRawDataPageLoader() {
  const [rawDataPage, setRawDataPage] = useState<JSX.Element | null>(null);

  useEffect(() => {
    async function loadRawDataPage() {
      const module = await System.import('@verily-src/phr-past-events-mfe');
      setRawDataPage(<module.RawDataPrimaryPage />);
    }

    loadRawDataPage();
  }, []);

  return rawDataPage;
}
