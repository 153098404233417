import {Grid, Typography} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

export function AsyncVaccinationsLoader() {
  const [vaccinations, setVaccinations] = useState<JSX.Element | null>(null);

  // TODO: Delete feature flag after merged to production
  const vaccinationsEnabled = config.getBoolean(
    'FEATURE_PHR_VACCINATIONS_ENABLED'
  );

  useEffect(() => {
    async function loadVaccinations() {
      const module = await System.import('@verily-src/phr-vaccinations-mfe');
      setVaccinations(<module.VaccinationsPrimaryCard />);
    }

    if (vaccinationsEnabled) {
      loadVaccinations();
    }
  }, []);

  if (!vaccinations) {
    return null;
  }

  return (
    <div style={{marginTop: '20px'}}>
      <Typography component={'h2'} variant="display6">
        More
      </Typography>
      <Grid container spacing={2} style={{marginTop: '12px'}}>
        <Grid item xs={6} md={3}>
          {vaccinations}
        </Grid>
      </Grid>
    </div>
  );
}
