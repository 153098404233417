import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {AsyncBiometricsLoader} from 'components/AsyncBiometricsLoader/AsyncBiometricsLoader';
import {AsyncPastEventsLoader} from 'components/AsyncPastEventsLoader/AsyncPastEventsLoader';
import {AsyncVaccinationsLoader} from 'components/AsyncVaccinationsLoader/AsyncVaccinationsLoader';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

type HealthDashboardPageProps = {
  header?: boolean;
};

export default function HealthDashboardPage({
  header,
}: HealthDashboardPageProps) {
  const {t} = useTranslation();

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('dashboard-header')}`;
  }, []);

  return (
    <Layout header={header ? <DashboardHeader /> : undefined}>
      <section>
        <AsyncBiometricsLoader />
      </section>
      <section>
        <AsyncPastEventsLoader />
      </section>
      <section>
        <AsyncVaccinationsLoader />
      </section>
    </Layout>
  );
}

function DashboardHeader() {
  const {t} = useTranslation();

  return <Header headerText={t('dashboard-header')} hasBackButton={false} />;
}
