import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

// TODO(ONEVERILY-61387): When feature flag is removed, remove this
// class and replace with `import {PastEvents} from '@verily-src/phr-past-events-mfe';`
export function AsyncPastEventsLoader() {
  const [pastEvents, setPastEvents] = useState<JSX.Element | null>(null);

  // TODO(ONEVERILY-61387): Delete feature flag after merged to production
  const pastEventsEnabled = config.getBoolean(
    'FEATURE_PHR_PAST_EVENTS_ENABLED'
  );

  useEffect(() => {
    async function loadPastEvents() {
      const module = await System.import('@verily-src/phr-past-events-mfe');
      console.log(module, module.PastEvents);
      setPastEvents(<module.PastEvents />);
    }

    if (pastEventsEnabled) {
      loadPastEvents();
    }
  }, []);

  if (!pastEvents) {
    return null;
  }

  return <div style={{marginTop: '16px'}}>{pastEvents}</div>;
}
